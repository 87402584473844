<template>
    <div class="dealerCommodityManagement-list">
      <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
        <el-dialog title="选择文件" :visible.sync="dialogVisibleUpload" @close="closeFun()" :close-on-click-modal="false">
        <div class="btn-list">
            <div class="up_add_bg" @click='onImgFunBtn'>选择文件
                <input type="file" class="upload-btn" @change="addFile" ref="inputer" id="fileupload" multiple='false'
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
            </div>
            <el-button class="download-btn"  @click="downloadExcel">下载模板</el-button>
        </div>
        <div class="upload_fileName">文件名称：{{fileName}}</div>
        <!-- .xls .xslx 格式 -->
        <div class="dialog_bottom">
          <el-button type="primary" :disabled="sureBtnFlag" @click="commitFile">确认</el-button>
          <el-button type="primary" @click="closeFun">关闭</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  <script>
    let validCode = (rule, value, callback) => {
      if (value == "" || value == undefined) {
        callback();
      } else {
        //支持输入大小写字母，数字，限制1~16位字符
        let reg = /^[A-z0-9]{1,32}$/;
        if (!reg.test(value)) {
          callback(new Error("商品编号格式不对"));
        } else {
          callback();
        }
      }
    };
    let validItemsName = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //仅支持输入数字、汉字，大小写字母，限制字符长度1~32个字符
            let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,32}$/;
            if (!reg.test(value)) {
                callback(new Error("商品名称格式不对或超过32个字符"));
            } else {
                callback();
            }
        }
    };
    import axios from "axios";
    import Vue from "vue";
    export default {
      data() {
        let _this = this;
        return {
          fileName: '',
          formData: new FormData(),
          file: {}, //文件数据  
          dialogVisibleUpload: false,
          sureBtnFlag: false,
          opt: {
            title: "商品管理",
            search: [
              // {
              //     key: "merchantId",
              //     label: "商家名称",
              //     type: 'remoteSearchById',
              //     opt: {
              //         list: []
              //     }
              // },
              {
                key: "typeName",
                label: "商品品类",
              },
              {
                key: "name",
                label: "商品名称",
                maxlength: 64,
                rules: [{
                    validator: validItemsName,
                    trigger: ['blur']
                }]
              },
              {
                key: "code",
                label: "商品编号",
                rules: [{
                  validator: validCode,
                  trigger: ['blur']
                }]
              }
            ],
            columns: [{
                label: "商品名称",
                key: "name",
                on: row => {
                    _this.$router.push("/main/dealerCommodityManagement/detail/show/" + row.id)
                }
              },
              {
                label: "商品编号",
                key: "code"
              },
              {
                label: "商品品类",
                key: "typeName"
              },
              {
                label: "成本价",
                key: "costPriceVal"
              },
              {
                label: "经销价",
                key: "supplyPriceVal"
              },
              // {
              //   label: "销售价",
              //   key: "sellPriceVal"
              // },
              // {
              //   label: "商家名称",
              //   key: "merchantName"
              // },
              {
                label: "商品详细描述",
                key: "detail"
              },
              {
                label: "商品图片",
                key: "pic",
                type: "img-shop"
              },
              {
                label: "上架时间",
                key: "updatedTime",
              },
              {
                label: "状态",
                key: "auditState"
              },
            ],
            buttons: [{
                type: 0,
                on() {
                  _this.$router.push("/main/dealerCommodityManagement/detail/add/0");
                }
              },
              {
                type: 1,
                on(data) {
                  console.log(data,'data---');
                  if (data.data.auditState == '审核通过') {
                    return _this.$message({
                      showClose: true,
                      message: "审核通过的商品不能编辑!",
                      type: "warning"
                    });
                  }
                  _this.$router.push("/main/dealerCommodityManagement/detail/edit/" + data.id);
                }
              },
              {
                type: 2,
                on(data) {
                  console.log(data,'data---');
                  if (data.ids.length > 1) {
                    return _this.$message({
                      showClose: true,
                      message: "不支持多个删除,只支持单个删除!",
                      type: "warning"
                    });
                  }
                  if (data.list[0].auditState == '审核通过') {
                    return _this.$message({
                      showClose: true,
                      message: "审核通过的商品不能删除!",
                      type: "warning"
                    });
                  }
                  const h = _this.$createElement;
                  let dto = {
                    itemsIds: data.ids,
                    state: 0
                  };
                  _this
                    .$msgbox({
                      title: "删除提示",
                      message: h("p", null, [
                        h(
                          "span", {
                            style: "color: #333;font-size: 16px;margin-left:98px"
                          },
                          "确定要删除商品吗？"
                        )
                      ]),
                      showCancelButton: true,
                      confirmButtonText: "确定",
                      cancelButtonText: "取消"
                    })
                    .then(action => {
                      _this
                        .post("mall-service/v1/items-info/delete", dto)
                        .then(function () {
                          _this.$message({
                            showClose: true,
                            message: "删除商品成功！",
                            type: "success"
                          });
                          data.refresh();
                        });
                    });
                }
              },
              {
                type: 3,
                name: "批量导入",
                on() {
                  _this.dialogVisibleUpload = true;
                  _this.fileName = "";
                }
              },
            ]
          },
        };
      },
      created() {
        console.log("dealerCommodityManagement-list created!!");
  
      },
      activated() {
        console.log("dealerCommodityManagement-list activated!!");
        this.getMerchantList()
      },
      mounted() {
      },
      methods: {
        onGet(opt) {
          let dto = {
            pageNum: opt.skip,
            pageSize: opt.limit,
            ...opt.searchForm
          };
          this.post("mall-service/items/page/query", dto, {
            isUseResponse: true
          }).then(res => {
            res.data.data = res.data.list;
              res.data.list.forEach(item => {
                item.costPriceVal = item.costPrice == null ? '' : item.costPrice.toFixed(2);
                item.supplyPriceVal = item.supplyPrice == null ? '' : item.supplyPrice.toFixed(2);
                item.detail = item.detail ? item.detail : "--";
                item.auditState = ["待审核","审核通过","审核不通过"][item.auditState];
                if (item.detail.length > 17) {
                  item.detail = item.detail.substr(0, 17) + '...';
                }
                if (item.name.length > 17) {
                  item.name = item.name.substr(0, 17) + '...';
                }
              })
              opt.cb(res.data);
  
          });
        },
        // 获取商家列表
        getMerchantList() {
            this.post('/mall-service/merchant/v1/page', {
                pageNumber: 1,
                pageSize: 9999,
                roleType: 1
            }).then(res => {
                var array = res.data;
                array.forEach(item => {
                    item.value = item.id;
                    item.label = item.name;
                })
                console.log(array);
                this.opt.search.forEach(item => {    
                    if(item.key == 'merchantId') {
                    item.opt.list = array
                    }
                })
            })
        },
        onImgFunBtn() {
          document.getElementById('fileupload').click();
        },
        //选择文件
        addFile: function (e) {
          var _this = this;
          let inputDOM = this.$refs.inputer;
          this.file = inputDOM.files[0];
          let len = this.file.length;
          let size = Math.floor(this.file.size / 1024);
          if (size > 50 * 1024 * 1024) {
            alert("请选择50M以内的文件！");
            return false;
          }
          this.fileName = document.getElementById('fileupload').files[0].name;
          this.formData.append("file", this.file);
          this.formData.append("roleType", 3);
          this.sureBtnFlag = false;
          e.target.value = '';
        },
        commitFile(){
            var _this = this;
            if (!this.fileName) {
                return this.$message({
                  message: "请选择文件",
                  type: "warning"
                });
            }
            this.sureBtnFlag = true;
            axios({
                method: "POST",
                url: _this.UPLOAD_URL + "/mall-service/items/importData",
                data: this.formData,
                headers: {
                accessToken: localStorage.getItem("token") || sessionStorage.getItem("token"),
                securityKey: localStorage.getItem("securityKey") || sessionStorage.getItem("securityKey"),
                'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                this.formData = new FormData();
                if (response.data.code == 0) {
                    this.$message({
                        type: 'success',
                        message: '导入成功!'
                    })
                    this.sureBtnFlag = false;
                    this.dialogVisibleUpload = false;
                    this.$refs.myList.get();
                } else {
                    this.$message({
                        type: 'warning',
                        message: response.data.msg
                    })
                    this.sureBtnFlag = true;
                }
            })
            .catch((error) => {
                this.sureBtnFlag = false;
                this.formData = new FormData();
            });
        },
        downloadExcel(){
            let url = ''
            if (Vue.config.BASE_URL == 'https://apartment-preapi.wfyundata.com' || Vue.config.BASE_URL == 'https://apartment-api.wavewisdom.com') {
                url = 'https://oss-image-prod.wfyundata.com/excel/%E5%AF%BC%E5%85%A5%E5%95%86%E5%93%81%E6%A8%A1%E6%9D%BF%28%E7%BB%8F%E9%94%80%E5%95%86%29.xls'
            } else {
                url = 'http://oss-image-prod.wfyundata.com/excel/%E5%AF%BC%E5%85%A5%E5%95%86%E5%93%81%E6%A8%A1%E6%9D%BF%28%E7%BB%8F%E9%94%80%E5%95%86%29.xls'
            }
            axios.get(url, {         
                responseType: 'blob',          
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));            
                const link = document.createElement('a');            
                let fname = '导入商品模板.xls';            
                link.href = url;            
                link.setAttribute('download', fname);            
                document.body.appendChild(link);            
                link.click();          
            }).catch(error => {            
                console.log('error:'+JSON.stringify(error))          
            });     
        },
        closeFun() {
          this.sureBtnFlag = false;
          this.dialogVisibleUpload = false;
          this.$refs.myList.get();
        }
      }
    };
  </script>
  <style lang="scss" scope>
  .el-dialog {
    margin-top: 28vh !important;
    width: 562px;
  }

  .el-dialog__footer {
    text-align: center;
  }

  .dialog_bottom {
    margin-top: 23px;
    width: 100%;
    text-align: center;

    .el-button {
      width: 80px !important;
      height: 36px !important;
      padding-top: 10px;
    }
  }

  .up_add_bg {
    width: 95px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    background: #FFFFFF;
    border: 1px solid #CFCFD7;
    border-radius: 6px;
    cursor: pointer;

    .upload-btn {
      opacity: 0;
    }
  }

  .upload_fileName {
    padding-top: 10px;
    padding-right: 5px;
  }
  .btn-list{
      display: flex;
  }
  .download-btn{
      margin-left: 10px;
  }
  </style>